export const storeLocationsData = [
	{
		id: 1,
		title: 'Store Locations',
		url: 'stores',
		children: [
			{
				id: 10,
				title: 'California',
				column: 1,
				hideBreadcrumb: true,
				children: [
					{
						id: 101,
						title: 'Costa Mesa',
						url: 'stores/costa-mesa',
					},
					{
						id: 102,
						title: 'Los Angeles',
						url: 'stores/los-angeles',
					},
					{
						id: 103,
						title: 'Palm Springs',
						url: 'stores/palm-springs',
					},
					{
						id: 104,
						title: 'Pasadena',
						url: 'stores/pasadena',
					},
					{
						id: 105,
						title: 'San Diego',
						url: 'stores/san-diego',
					},
					{
						id: 106,
						title: 'San Francisco',
						url: 'stores/san-francisco',
					},
				],
			},
			{
				id: 20,
				title: 'Colorado',
				column: 1,
				hideBreadcrumb: true,
				children: [
					{
						id: 201,
						title: 'Denver',
						url: 'stores/denver',
					},
				],
			},
			{
				id: 30,
				title: 'Georgia',
				column: 1,
				hideBreadcrumb: true,
				children: [
					{
						id: 301,
						title: 'Atlanta',
						url: 'stores/atlanta',
					},
				],
			},
			{
				id: 40,
				title: 'Illinois',
				column: 1,
				hideBreadcrumb: true,
				children: [
					{
						id: 401,
						title: 'Chicago',
						url: 'stores/chicago',
					},
					{
						id: 402,
						title: 'Oak Brook – Store & Outlet',
						url: 'stores/oak-brook',
					},
					{
						id: 403,
						title: 'Skokie',
						url: 'stores/skokie',
					},
				],
			},
			{
				id: 45,
				title: 'Maryland',
				column: 1,
				hideBreadcrumb: true,
				children: [
					{
						id: 451,
						title: 'Bethesda',
						url: 'stores/bethesda',
					},
				],
			},
			{
				id: 50,
				title: 'Massachusetts',
				column: 1,
				hideBreadcrumb: true,
				children: [
					{
						id: 501,
						title: 'Boston',
						url: 'stores/boston',
					},
				],
			},
			{
				id: 60,
				title: 'Minnesota',
				column: 2,
				hideBreadcrumb: true,
				children: [
					{
						id: 601,
						title: 'Edina',
						url: 'stores/edina',
					},
					{
						id: 602,
						title: 'Weekend Outlet – Golden Valley',
						url: 'stores/outlet',
					},
				],
			},
			{
				id: 120,
				title: 'New Jersey',
				column: 2,
				hideBreadcrumb: true,
				children: [
					{
						id: 1201,
						title: 'Paramus',
						url: 'stores/paramus',
					},
				],
			},
			{
				id: 70,
				title: 'New York',
				column: 2,
				hideBreadcrumb: true,
				children: [
					{
						id: 701,
						title: 'New York City',
						url: 'stores/chelsea',
					},
					{
						id: 702,
						title: 'East Hampton',
						url: 'stores/east-hampton',
					},
				],
			},
			{
				id: 80,
				title: 'Oregon',
				column: 2,
				hideBreadcrumb: true,
				children: [
					{
						id: 801,
						title: 'Portland',
						url: 'stores/portland',
					},
				],
			},
			{
				id: 90,
				title: 'Texas',
				column: 2,
				hideBreadcrumb: true,
				children: [
					{
						id: 902,
						title: 'Austin',
						url: 'stores/austin',
					},
					{
						id: 901,
						title: 'Dallas',
						url: 'stores/dallas',
					},
				],
			},
			{
				id: 100,
				title: 'Washington',
				column: 2,
				hideBreadcrumb: true,
				children: [
					{
						id: 1001,
						title: 'Seattle',
						url: 'stores/seattle',
					},
				],
			},
			{
				id: 110,
				title: 'Washington, D.C.',
				column: 2,
				hideBreadcrumb: true,
				children: [
					{
						id: 1101,
						title: 'Washington, D.C.',
						url: 'stores/washington-dc',
					},
				],
			},
		],
	},
];
